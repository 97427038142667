import { createStore } from "vuex";
import { playbook } from "./playbook";
import { deal_assist } from "./deal-assist";
import { momentum_support } from "./momentum-support";
import { notifications } from "./notifications";
import { templates } from "./templates";
import { validations } from "./validations";
import { wizards } from "./wizards";
import { gifs } from "./gifs";
import { homeDashboard } from "./home-dashboard";
import { emailFollowUp } from "./email-follow-up";
import { autopilot } from "./autopilot";
import { meeting } from "./meeting";
import { integrations } from "./integrations";
import { backfill } from "./backfill";

const store = createStore({
  modules: {
    backfill: {
      namespaced: true,
      ...backfill,
    },
    playbook: {
      namespaced: true,
      ...playbook,
    },
    "deal-assist": {
      namespaced: true,
      ...deal_assist,
    },
    integrations: {
      namespaced: true,
      ...integrations,
    },
    "momentum-support": {
      namespaced: true,
      ...momentum_support,
    },
    notifications: {
      namespaced: true,
      ...notifications,
    },
    templates: {
      namespaced: true,
      ...templates,
    },
    validations: {
      namespaced: true,
      ...validations,
    },
    wizards: {
      namespaced: true,
      ...wizards,
    },
    gifs: {
      namespaced: true,
      ...gifs,
    },
    homeDashboard: {
      namespaced: true,
      ...homeDashboard,
    },
    emailFollowUp: {
      namespaced: true,
      ...emailFollowUp,
    },
    autopilot: {
      namespaced: true,
      ...autopilot,
    },
    meeting: {
      namespaced: true,
      ...meeting,
    },
  },
  state() {
    return {
      // authUser and profile may or may not be the same person.
      apiErrors: [],
      authUser: null,
      apollo: null,
      // profile is only used for display purposes and represents either the logged in user or the impersonated user.
      profile: null,
      showSideBar: false,
      header: "",
      breadcrumbs: [],
      authToken: null,
      orgData: null,
      workflows: [],
      featureFlag: null,
    };
  },
  mutations: {
    toggleSideBar(state, status) {
      state.showSideBar = status;
    },
    setAuthUser(state, authUser) {
      state.authUser = authUser;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setApollo(state, apollo) {
      state.apollo = apollo;
    },
    setHeader(state, header) {
      state.header = header;
    },
    setApiErrors(state, errors) {
      state.apiErrors = errors;
    },
    setOrgData(state, settings) {
      state.orgData = settings;
    },
    setWorkflows(state, workflows) {
      state.workflows = workflows;
    },
    setFeatureFlag(state, featureFlag) {
      state.featureFlag = featureFlag;
    },
  },
  actions: {
    setAuthUser({ commit }, user) {
      const authUser = {
        name: user.name,
        email: user.email,
        picture: user.picture,
      };
      if (user["https://hasura.io/jwt/claims"]) {
        const hasuraClaims = user["https://hasura.io/jwt/claims"];
        authUser.userId = parseInt(hasuraClaims["x-hasura-user-id"]) || null;
        authUser.organizationId = parseInt(hasuraClaims["x-hasura-organization-id"]);
        authUser.role = hasuraClaims["x-hasura-default-role"];
        authUser.allowedRoles = hasuraClaims["x-hasura-allowed-roles"];
        authUser.isMomentumSupport = authUser.allowedRoles.includes("momentum-support");
      }
      commit("setAuthUser", authUser);
    },
    setProfile({ commit }, profileUser) {
      commit("setProfile", {
        name: profileUser.name,
        email: profileUser.email,
        id: profileUser.id,
        picture: profileUser.picture,
        organizationName: profileUser.organization?.name,
        userLicense: profileUser.userLicense,
      });
    },
    setApollo({ commit }, apollo) {
      commit("setApollo", apollo);
    },
    toggleSideBar({ commit }, status) {
      commit("toggleSideBar", status);
    },
    setHeader({ commit }, header) {
      commit("setHeader", header);
    },
    setApiErrors({ commit }, errors) {
      commit("setApiErrors", errors);
    },
    setOrgData({ commit }, settings) {
      commit("setOrgData", settings);
    },
    setWorkflows({ commit }, workflows) {
      commit("setWorkflows", workflows);
    },
    setFeatureFlag({ commit }, featureFlag) {
      commit("setFeatureFlag", featureFlag);
    },
  },
});

export default store;
