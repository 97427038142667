import { createAuthGuard } from "@auth0/auth0-vue";
import { createRouter, createWebHistory } from "vue-router";
import { createAdminRoutes } from "./admin.js";
import { createMainAppRoutes } from "./main-app.js";
import { isSupportConsole } from "../utils/is-support-console.js";
import { isRoleEditor } from "../utils/roles";
import { toggleSideBar } from "../utils/mobile";

export function getRouters(app) {
  const authGuard = createAuthGuard(app);
  const routes = [
    {
      path: "/",
      name: "base",
      redirect: () => {
        if (isSupportConsole()) {
          return "/momentum-support";
        }
        return "/admin/notifications/ai-summary";
      },
    },

    // auth
    {
      path: "/auth/callback",
      name: "auth-callback",
      component: () => import("../pages/AuthCallback.vue"),
    },
    {
      path: "/password-login",
      name: "password-login",
      component: () => import("../pages/PasswordLogin.vue"),
    },

    // //new non-admin app routes
    ...createMainAppRoutes(app),
    // //all admin app routes
    ...createAdminRoutes(app),

    // integration authentication success page
    {
      path: "/integration/authentication/success",
      name: "integration-authentication-success",
      component: () => import("../pages/IntegrationAuthenticationSuccess.vue"),
    },

    // integration authentication failure page
    {
      path: "/integration/authentication/failure",
      name: "integration-authentication-failure",
      component: () => import("../pages/IntegrationAuthenticationFailure.vue"),
    },

    // deal assist webhook success page
    {
      path: "/webhook/deal-assist/success",
      name: "dealassist-webhook-success",
      component: () => import("../pages/DealAssistWebhookSuccess.vue"),
    },

    //momentum support
    {
      path: "/momentum-support",
      name: "momentum-support-home",
      component: () => import("../pages/momentum-support/MomentumSupportHome.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Support Home",
        layout: "AppLayoutDefault",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId",
      name: "momentum-support-organization",
      component: () => import("../pages/momentum-support/MomentumSupportOrganization.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Users",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/service-accounts",
      name: "momentum-support-organization-service-accounts",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationServiceAccounts.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Service Accounts",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/fake-meeting",
      name: "momentum-support-fake-meeting",
      component: () => import("../pages/momentum-support/MomentumSupportFakeMeeting.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Fake Meeting",
        layout: "AppLayoutDefault",
      },
    },
    {
      path: "/momentum-support/meetings",
      name: "momentum-support-meetings",
      component: () => import("../pages/momentum-support/MomentumSupportMeetings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meetings",
        layout: "AppLayoutDefault",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definitions",
      name: "momentum-support-organization-assist-definitions",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAssistDefinitions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Assist Definitions",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId",
      name: "momentum-support-organization-assist-definition",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAssistDefinition.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Assist Definition",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId/assists",
      name: "momentum-support-organization-assists",
      component: () => import("../pages/momentum-support/OrganizationAssists.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assists",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/assist-definition/:assistDefinitionId/assists/:assistId",
      name: "momentum-support-organization-assist",
      component: () => import("../pages/momentum-support/OrganizationAssist.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/digest-definitions",
      name: "momentum-support-organization-digest-definitions",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationDigestDefinitions.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Digest Definitions",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/digest-definition/:digestDefinitionId",
      name: "momentum-support-organization-digest-definition",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationDigestDefinition.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Digest Definition",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meetings",
      name: "momentum-support-organization-meetings",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationMeetings.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meetings",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId",
      name: "momentum-support-organization-meeting",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationMeeting.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/meeting/:meetingId/ai-tasks",
      name: "momentum-support-organization-meeting-ai-tasks",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationMeetingAiTasks.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Meeting AI Tasks",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/meeting-source-event",
      name: "momentum-support-meeting-source-event",
      component: () => import("../pages/momentum-support/MomentumSupportMeetingSourceEvent.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Meeting Source Event",
        layout: "AppLayoutDefault",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/logs",
      name: "momentum-support-organization-logs",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationLogs.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "View Organization Logs",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-tasks",
      name: "momentum-support-organization-ai-tasks",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAiTasks.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Tasks",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-task/:aiTaskId",
      name: "momentum-support-organization-ai-task",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAiTask.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Task",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-llm-request/:aiLlmRequestId",
      name: "momentum-support-organization-ai-llm-request",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAiLlmRequest.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI LLM Request",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/ai-embedding-search/:aiEmbeddingSearchId",
      name: "momentum-support-organization-ai-embedding-search",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAiEmbeddingSearch.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization AI Embedding Search",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/settings",
      name: "momentum-support-organization-settings",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationAttributes.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization Settings",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/user/:userId",
      name: "momentum-support-organization-user",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationUser.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization User",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/organization/:organizationId/user/:userId/auth0-logs",
      name: "momentum-support-organization-user-auth0-logs",
      component: () => import("../pages/momentum-support/MomentumSupportOrganizationUserAuth0Logs.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Organization User Auth0 Logs",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/zoom-events",
      name: "momentum-support-zoom-events",
      component: () => import("../pages/momentum-support/MomentumSupportZoomEvents.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Zoom Events",
        layout: "AppLayoutDefault",
      },
    },
    {
      path: "/momentum-support/:organizationId/feature-flags",
      name: "momentum-support-organization-feature-flags",
      component: () => import("../pages/momentum-support/FeatureFlags.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feature Flags",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/:organizationId/salesforce-flows",
      name: "momentum-support-organization-salesforce-flows",
      component: () => import("../pages/momentum-support/SalesforceFlows.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Salesforce Flows",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/:organizationId/feed-payloads",
      name: "momentum-support-organization-feed-payloads",
      component: () => import("../pages/momentum-support/FeedPayloads.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feed Payloads",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/:organizationId/feed-payload/:feedPayloadId",
      name: "momentum-support-organization-feed-payload",
      component: () => import("../pages/momentum-support/FeedPayload.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Feed Payload",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/:organizationId/assist-trigger-payloads",
      name: "momentum-support-organization-assist-trigger-payloads",
      component: () => import("../pages/momentum-support/AssistTriggerPayloads.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Trigger Payloads",
        layout: "MomentumSupport",
      },
    },
    {
      path: "/momentum-support/:organizationId/assist-trigger-payload/:assistTriggerPayloadId",
      name: "momentum-support-organization-assist-trigger-payload",
      component: () => import("../pages/momentum-support/AssistTriggerPayload.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Assist Trigger Payload",
        layout: "MomentumSupport",
      },
    },

    //zendesk integration support
    {
      path: "/integration-zendesk-subdomain-input",
      name: "integration-zendesk-subdomain-input",
      component: () => import("../pages/IntegrationZendeskSubdomainInput.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },

    //non auth routes
    {
      path: "/sign-in",
      name: "UserLogin",
      component: () => import("../pages/SignIn.vue"),
      meta: {
        title: "Login",
        layout: "UserLogin",
      },
    },
    {
      path: "/configuration-error",
      name: "configuration-error",
      component: () => import("../pages/ConfigurationError.vue"),
      meta: {
        title: "Error",
        layout: "AppLayoutDefault",
      },
    },

    //this always needs to be last to catch any invalid paths
    //if route doesn't match from anything above serve a Page Not Found
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("../pages/PageNotFound.vue"),
      meta: {
        layout: "AppLayoutDefault",
      },
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeResolve((to, _from, next) => {
    if (to.path.startsWith("/admin/") && !isRoleEditor()) {
      // only editors can access /admin/ pages
      next({ name: "meetings" });
    } else if (isSupportConsole()) {
      // allow access to all pages on the support-console
      next();
    } else if (to.path.includes("/integration/authentication/success", "/integration/authentication/failure")) {
      // static pages, so no need to check for auth
      next();
    } else {
      next();
    }
  });

  router.beforeEach((to, _from, next) => {
    const id = to.params?.id ?? "";
    const title = to.meta?.title;
    const pageName = (title ?? to.name ?? "")
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
    document.title = `Momentum - ${pageName}${id ? " - " + id : ""}`.trim();
    toggleSideBar();

    next();
  });

  router.afterEach(() => {
    if (window.analytics) {
      window.analytics.page();
    }
  });

  return { router, routes };
}
