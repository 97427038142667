/* eslint-disable no-unused-vars */

import { IntegrationServiceEnum } from "./integrations";

export interface User {
  deleted_at?: Date | null;
  email: string;
  id: number;
  isExternal: boolean;
  name: string; // deprecated, do not use
  picture: string;
  slackUserId: string;
  label?: string; // invalid user field
  slackUserProfile: {
    real_name: string;
  };
  fullName: string;
  role: UserRoleEnum;
  slackUserName: string;
  salesforceUserRole?: {
    name: string;
  };
  userId?: number;
  integrations?: TUserIntegration[];
  userInvitations?: {
    [key: string]: string;
  }[];
  hasCompletedSignup?: boolean;
  userLicense: TAiLicenseConstraints;
  hasLicenseAssigned?: boolean;
  type: TUserType;
}

export type TUserIntegration = {
  service: IntegrationServiceEnum;
  status: string;
  useForOrganization?: boolean;
};

export interface TTeamUser extends User {
  salesforceUserRoleName?: string;
}

export type TSelectUserOption = {
  momentumUser: User;
  label: string;
  value: string | number;
  id: string | number;
  momentumUserId: number;
  disabled: boolean;
  type: "user" | "binding";
};

export interface TAuthUser {
  allowedRoles: AccessRoleEnum[];
  email: string;
  isMomentumSupport: boolean;
  name: string;
  organizationId: number;
  picture: string;
  role: AccessRoleEnum;
  userId?: number | null;
}

export interface SelectOptionUser extends User {
  label: string;
  value: any;
  id: any;
}

export type TMomentumUser = {
  id: number;
  email: string;
  fullName: string;
};

export type TUserCount = {
  allUsers: number;
  admins: number;
  editors: number;
  users: number;
  aiLicensesUsed: number;
  aiLicensesProvisioned: number;
  aiLicensesAvailable: number;
};

export enum UserInvitationStatusEnum {
  PENDING = "pending",
}

export enum TeamActionsEnum {
  INVITE = "INVITE",
  RESEND_INVITE = "RESEND_INVITE",
  MAKE_EDITOR = "MAKE_EDITOR",
  MAKE_USER = "MAKE_USER",
  MAKE_ADMIN = "MAKE_ADMIN",
  UPDATE_ROLE = "UPDATE_ROLE",
  ASSIGN_AI_LICENSE_SETTINGS = "ASSIGN_AI_LICENSE_SETTINGS",
  UPDATE_AI_LICENSE_SETTINGS = "UPDATE_AI_LICENSE_SETTINGS",
  REMOVE_AI_LICENSE_SETTINGS = "REMOVE_AI_LICENSE_SETTINGS",
  REMOVE_USER = "REMOVE_USER",
}

export enum UserRoleEnum {
  EDITOR = "editor",
  ORGANIZATION_ADMIN = "organization-admin",
  USER = "user",
}

export type TUserType = "service-account" | "slack-user" | "standard";

export const userRoleNames: Record<UserRoleEnum, string> = {
  [UserRoleEnum.USER]: "User",
  [UserRoleEnum.EDITOR]: "Editor",
  [UserRoleEnum.ORGANIZATION_ADMIN]: "Organization Admin",
};

export enum SupportRoleEnum {
  MOMENTUM_SUPPORT = "momentum-support",
}

export type AccessRoleEnum = UserRoleEnum | SupportRoleEnum;

export enum EmailSourceEnum {
  SALESFORCE = "SALESFORCE",
}

export const emailSourceNames: Record<EmailSourceEnum, string> = {
  [EmailSourceEnum.SALESFORCE]: "Salesforce",
};

export enum VideoCallSourceEnum {
  CHORUS = "CHORUS",
  CLARI_COPILOT = "CLARI_COPILOT",
  GONG = "GONG",
  MOMENTUM_NATIVE = "MOMENTUM_NATIVE",
}

export const videoCallSourceNames: Record<VideoCallSourceEnum, string> = {
  [VideoCallSourceEnum.CHORUS]: "Chorus",
  [VideoCallSourceEnum.CLARI_COPILOT]: "Clari Copilot",
  [VideoCallSourceEnum.GONG]: "Gong",
  [VideoCallSourceEnum.MOMENTUM_NATIVE]: "Momentum Recorder",
};

export type TMomentumRecorderSettings = {
  botRecordExternallyHostedMeetings?: boolean;
  botRecordNonZoomSources?: boolean;
  botRecordZoomSources?: boolean;
  googleMeetNativeEnabled?: boolean;
  zoomNativeEnabled?: boolean;
};

export type TUserLicenseConstraints = {
  chorusEnabled: boolean;
  emailImport?: boolean;
  gongEnabled: boolean;
  momentumRecording: TMomentumRecorderSettings;
  wingmanEnabled: boolean;
};

export type TAiLicenseConstraints = {
  emailImport?: boolean;
  meetingImportAndRecordGoogleCalendarSources?: boolean;
  meetingImportAndRecordZoomSources?: boolean;
  meetingBotRecordZoomSources?: boolean;
  meetingBotRecordNonZoomSources?: boolean;
  meetingBotRecordExternallyHostedMeetings?: boolean;
  meetingImportChorusSources?: boolean;
  meetingImportGongSources?: boolean;
  meetingImportWingmanSources?: boolean;
};

export enum UserAiLicenseSettingTypeEnum {
  CHORUS = "CHORUS",
  EMAIL = "EMAIL",
  GONG = "GONG",
  MOMENTUM = "MOMENTUM",
  WINGMAN = "WINGMAN",
}

export interface IVideoCallSource {
  [key: string]: string;
}

export type TAiLicenseSettingsForm = {
  emailSettings: TEmailSettings;
  videoCallSource: string;
  momentumRecorderSettings: TMomentumRecorderSettings;
};

export type TEmailSettings = {
  emailImport: boolean;
};
