import { TFeatureValue } from "./types";
export function renderFeatureVariableType(value: TFeatureValue): string {
  let variableType: string = "";
  switch (typeof value) {
    case "boolean":
      variableType = "Boolean!";
      break;
    case "number":
      variableType = "Float";
      break;
  }

  return variableType;
}
