import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { BaseApi } from "../base";
import { TFeatureValue } from "./types";
import { renderFeatureVariableType } from "./utils";

export class FeaturesApi extends BaseApi {
  async getForSupport(organizationId: number): Promise<Record<string, TFeatureValue>> {
    type TVariables = { organizationId: number };
    type TData = { momentum: { organization: { getFeaturesForSupport: Record<string, TFeatureValue> } } };
    const query: DocumentNode = gql`
      query GetOrganizationFeaturesForSupport($organizationId: Int!) {
        momentum {
          organization {
            getFeaturesForSupport(organizationId: $organizationId)
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { organizationId });
      return result.momentum.organization.getFeaturesForSupport;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getBoolean(feature: string): Promise<boolean> {
    type TVariables = { feature: string };
    type TData = { momentum: { organization: { getFeatures: Record<string, boolean> } } };
    const query: DocumentNode = gql`
      query GetFeatureForBooleanValue($feature: [String!]!) {
        momentum {
          organization {
            getFeatures(names: $feature)
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { feature });
      return result.momentum.organization.getFeatures[feature];
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getNumber(feature: string): Promise<number> {
    type TVariable = { feature: string };
    type TData = { momentum: { organization: { getFeatures: Record<string, number> } } };
    const query: DocumentNode = gql`
      query GetFeatureForNumberValue($feature: String!) {
        momentum {
          organization {
            getFeatures(names: [$feature])
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariable>(query, { feature });
      return result.momentum.organization.getFeatures[feature];
    } catch (error) {
      await this.handleError(error);
    }
  }

  async getMultiple(features: string[]): Promise<Record<string, TFeatureValue>> {
    type TVariables = { features: string[] };
    type TData = { momentum: { organization: { getFeatures: Record<string, TFeatureValue> } } };
    const query: DocumentNode = gql`
      query GetFeatures($features: [String!]!) {
        momentum {
          organization {
            getFeatures(names: $features)
          }
        }
      }
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, { features });
      return result.momentum.organization.getFeatures;
    } catch (error) {
      await this.handleError(error);
    }
  }

  async updateForSupport(variables: { organizationId: number; flag: string; value: TFeatureValue }): Promise<boolean> {
    type TVariables = { organizationId: number; value: TFeatureValue };
    type TData = { momentum: { organization: { updateFeatures: { accepted: boolean } } } };
    const query: DocumentNode = gql`
      mutation UpdateOrganizationFeatureFlagForSupport($organizationId: Int!, $value: ${renderFeatureVariableType(
        variables.value
      )}) {
        momentum {
          organization {
            updateFeatures(input: { ${variables.flag}: $value }, organizationId: $organizationId) {
              accepted
            }
          }
        }
      }
    `;
    try {
      const result: TData = await this.mutate<TData, TVariables>(query, {
        organizationId: variables.organizationId,
        value: variables.value,
      });
      this.postSuccess(`Feature ${variables.flag} updated successfully`);
      return result.momentum.organization.updateFeatures.accepted;
    } catch (error) {
      await this.handleError(error);
    }
  }
}
