<template>
  <div>
    <img
      v-if="!state.showFallbackAvatar"
      loading="lazy"
      class="w-6 h-6 rounded-full"
      :src="imageSrc"
      :alt="altText"
      :class="customClass"
      @error="handleImageError"
    >
    <MFirstCharOfName
      v-else
      :name="name"
      :custom-class="customClass"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import MFirstCharOfName from "./MFirstCharOfName.vue";

export default defineComponent({
  components: {
    MFirstCharOfName,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    altText: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  setup() {
    interface State {
      showFallbackAvatar: boolean;
    }
    const state: State = reactive({
      showFallbackAvatar: false,
    });

    function handleImageError(event: Event): void {
      // Handle the image loading error
      event.target.style.display = "none"; // Hide the broken image
      state.showFallbackAvatar = true;
    }

    return {
      state,
      handleImageError,
    };
  },
});
</script>
