import { TBackfill } from "../api/backfill/types";

interface State {
  backfill: TBackfill;
  backfillsCreated: TBackfill[];
}
export const backfill = {
  state: () =>
    <State>{
      backfill: null,
      backfillsCreated: [],
    },

  mutations: {
    setBackfill(state: State, backfill: TBackfill): void {
      state.backfill = backfill;
    },
    setBackfillsCreated(state: State, backfills: TBackfill[]): void {
      state.backfillsCreated = backfills;
    },
  },

  actions: {
    setBackfill({ commit }, backfill: TBackfill): void {
      commit("setBackfill", backfill);
    },
    setBackfillsCreated({ commit }, backfills: TBackfill[]): void {
      commit("setBackfillsCreated", backfills);
    },
  },
};
